<template>
  <h1>Welcome to 365 Fit Hub</h1>
  <p>Platform is under construction</p>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>
