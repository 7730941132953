import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'

const getFitApp = createApp(App)

// getFitApp.component(name: 'NiceButton', component { })
// getFitApp.use(SomePlugin)
getFitApp.use(router)

getFitApp.mount('#app')
