<template>
  <div class="col-full text-center">
    <h1>Oops! The Page Was Not Found</h1>
    <router-link :to="{name: 'Home'}">Go to the home page</router-link>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
