import { createRouter, createWebHistory } from 'vue-router'
import Home from '@/pages/Home'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/pages/NotFound.vue')
  }
]

export default createRouter({
  // history: VueRouter.createWebHashHistory(),
  history: createWebHistory(),
  routes
})
